<template>
  <div class="task-add">
    <div class="main-Title bgff"><h2>Add Task</h2></div>
    <div class="mainBox">
      <el-card shadow="hover">
        <el-form ref="postData" :model="postData" :rules="rules" label-width="90px">
          <!-- <el-form-item label="节点" prop="nodeId">
						<el-select v-model="postData.nodeId" placeholder="Please select Node" style="width: 100%">
							<el-option v-for="item in options.node" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item> -->
          <el-form-item label="offer ID" prop="offerId"
            ><el-input v-model="postData.offerId" :disabled="true" placeholder="请输入offer ID"
          /></el-form-item>
          <el-form-item label="Country" prop="country"
            ><el-input v-model="postData.country" :disabled="true" placeholder="请输入country"
          /></el-form-item>

          <el-form-item label="定时数据包" prop="dataSourceIds">
            <el-select
              v-model="postData.dataSourceIds"
              placeholder="Please select dataSourceId"
              class="w100"
              multiple
              filterable
              clearable
            >
              <el-option
                v-for="item in options.dataList"
                :key="item.id"
                :label="`${item.deviceName} --> 设备数: ${item.deviceCount}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="实时数据包" prop="dataSourceId">
            <el-select
              v-model="postData.dataSourceId"
              placeholder="Please select dataSourceId"
              class="w100"
              filterable
              clearable
            >
              <el-option
                v-for="item in options.dataListRealTime"
                :key="item.id"
                :label="`${item.deviceName} --> 设备数: ${item.deviceCount}`"
                :value="item.id"
              />
            </el-select>
          </el-form-item>

          <el-form-item label="点击数" prop="clickNumber">
            <el-input v-model.number="postData.clickNumber" placeholder="请输入点击数" />
            <!-- <el-select
              v-model="postData.clickNumber"
              placeholder="Please select Click Number"
              style="width: 100%"
            >
              <el-option
                v-for="item in options.clickNumber"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select> -->
          </el-form-item>
          <!--          <el-form-item label="OF" prop="emuId">
            <el-select
              v-model="postData.emuId"
              placeholder="Please select OF"
              style="width: 100%"
              filterable
            >
              <el-option
                v-for="item in options.emu"
                :key="item.id"
                :label="item.emuName"
                :value="item.id"
              />
            </el-select>
          </el-form-item>-->
          <!--          <el-form-item label="转化率" prop="convRate">
            <el-input v-model="postData.convRate" placeholder="请输入转化率">
              <template slot="append">
                %
              </template>
            </el-input>
          </el-form-item>-->
          <el-form-item label="开始时间" prop="">
            <el-time-picker
              v-model="postData.startTime"
              placeholder="任意时间点"
              value-format="HH:mm:ss"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="结束时间" prop="">
            <el-time-picker
              v-model="postData.endTime"
              placeholder="任意时间点"
              value-format="HH:mm:ss"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="是否更新" prop="needUpdate">
            <el-select
              v-model="postData.needUpdate"
              placeholder="Please select need Update"
              style="width: 100%"
            >
              <el-option
                v-for="item in options.needUpdate"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="Sub_aff" prop="subAff">
            <el-button type="primary" size="mini" @click="randomFun()">随机生成</el-button>
            <div class="random">{{ random }}</div>
            <el-input
              type="textarea"
              placeholder="请输入subAff"
              :autosize="{ minRows: 5, maxRows: 10 }"
              v-model="postData.subAff"
            ></el-input>
          </el-form-item> -->
          <el-row :gutter="20">
            <el-col :span="24" align="center">
              <el-form-item label-width="0px">
                <el-button v-loading="loading.btn" type="success" @click="add('postData')"
                  >保存</el-button
                >
                <el-button :loading="loading.btn" type="primary" @click="goOff()">取消</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
  import optionData from '@/assets/js/optionData';
  import { fetchEMUList } from '@/api/DDJ/v3/emu';
  import { handleTaskAdd, fetchSubAff, fetchTaskMixClickCount } from '@/api/DDJ/v3/task';
  import { fetchDataResultList } from '@/api/DDJ/v3/data';
  export default {
    data() {
      return {
        postData: {
          offerId: this.$route.query.offerId,
          country: this.$route.query.country,
          os: this.$route.query.os,
          offerUuid: this.$route.query.id,
          needUpdate: 1,
          startTime: '00:00:00',
          endTime: '23:59:59',
          subAff: '',
          dataSourceIds: [],
        },
        options: {
          emu: [],
          clickNumber: optionData.clickNumberOption,
          needUpdate: [
            { label: '是', value: 1 },
            { label: '否', value: 0 },
          ],
          dataList: [],
          dataListRealTime: [],
          dataSourceType: [
            { label: '定时更新', value: '0' },
            { label: '实时', value: '1' },
          ],
        },
        loading: {
          btn: false,
        },
        random: '',
        rules: {
          // clickNumber: [
          //   {
          //     required: true,
          //     message: '请选择点击数',
          //     trigger: 'blur',
          //   },
          // ],
          clickNumber: [
            {
              type: 'number',
              required: true,
              message: '请输入点击数',
              trigger: 'blur',
            },
          ],
          /* dataSourceIds: [
            {
              required: true,
              message: '请选择数据包',
              trigger: 'blur',
            },
          ],*/
          /*dataSourceId: [
            {
              required: true,
              message: '请选择数据包',
              trigger: 'blur',
            },
          ],*/
          /*emuId: [
            {
              //required: true,
              message: '请选择OF',
              trigger: 'blur',
            },
          ],*/
          convRate: [{ message: '请输入CR', trigger: 'blur' }],
        },
      };
    },
    /*watch: {
      'postData.dataSourceType'(newValue, oldValue) {
        this.options.dataList = [];
        this.postData.dataSourceIds = [];
        this.getDataList(newValue);
        console.log(oldValue);
      },
    },*/
    created() {
      //this.getMixClickCount(this.postData.offerId);
      // this.getSubAff();
      this.getEMU();
      this.getDataList(1);
      this.getDataList(0);
    },
    methods: {
      // 生成随机
      randomFun() {
        var len = 15;
        var $chars =
          'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
        var maxPos = $chars.length;
        var pwd = '';
        for (let i = 0; i < len; i++) {
          pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
        }
        this.random = pwd;
      },
      // 格式化subAff
      getSubAffStr() {
        let subAffSplit = this.postData.subAff.replaceAll('\n', ',').split(',');
        let returnSubAff;
        for (let i = 0; i < subAffSplit.length; i++) {
          if (subAffSplit[i] != '' && subAffSplit[i] != 'undefined') {
            if (i == 0) {
              returnSubAff = subAffSplit[i].trim();
            } else {
              returnSubAff = returnSubAff + ',' + subAffSplit[i].trim();
            }
          }
        }
        return returnSubAff;
      },
      getSubAff() {
        fetchSubAff({
          offerId: this.postData.offerId,
          advertiserName: this.$route.query.advertiserName,
        }).then((res) => {
          this.postData.subAff = res.result.subAff.replaceAll(',', '\n');
        });
      },
      // 获取emu
      getEMU() {
        fetchEMUList().then((res) => {
          console.log(res);
          this.options.emu = res.result;
        });
      },
      getDataList(dataSourceType) {
        fetchDataResultList({
          country: this.postData.country,
          os: this.postData.os,
          dataSourceType,
        }).then((res) => {
          if (res.result) {
            if (dataSourceType == 1) {
              this.options.dataListRealTime = res.result;
            } else {
              this.options.dataList = res.result;
            }
          }
        });
      },
      async getMixClickCount(id) {
        fetchTaskMixClickCount(id).then((res) => {
          console.log(res);
          this.postData.clickNumber = res.result.clickCount;
        });
      },
      // 保存
      add(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          let query = Object.assign({}, this.postData);
          query.dataSourceIds = this.postData.dataSourceIds.toString();
          // query.subAff = this.getSubAffStr();
          this.loading.btn = true;
          handleTaskAdd(query).then((res) => {
            console.log(res);
            if (res.code === 200) {
              this.$message({
                message: '添加成功',
                type: 'success',
              });
              this.$router.push({
                path: '/click/task/v3',
              });
            } else {
              this.$message.error(res.message);
            }
            this.loading.btn = false;
          });
        });
      },
      goOff() {
        this.$router.push({
          path: '/click/task/v3',
        });
      },
    },
  };
</script>

<style></style>
